
import {get,post} from "@/axios";

//网站页面
export function getSiteList(data) {
    return get('/api/sites/list',data);
}
export function addSite(data) {
    return post('/api/sites/add',data);
}
export function editSite(data) {
    console.log(data,'接收到的数据')
    return post('/api/sites/edit',data);
}
export function getDomainHistory(data) {
    return get('/api/sites/getDomainHistory',data);
}

export function deleteDomainHistory(data) {
    return post('/api/sites/deleteDomainHistory',data);
}
//获取所有站点
export function getAllSites(data) {
    return get('/api/sites/getAllSites',data);
}
//删除网站
export function sitesDelete(data) {
    return post('/api/sites/delete',data);
}
//设置邮箱
export function setMailConfig(data) {
    return post('/api/sites/setMailConfig',data);
}

//
export function updateSitesInfo(data) {
    return post('/api/sites/updateSitesInfo',data);
}

//设置支付方式
export function setPaymentInfo(data) {
    return post('/api/sites/setPaymentInfo',data);
}

//设置图片
export function setSitesImages(data) {
    return post('/api/sites/setSitesImages',data);
}

//设置物流
export function setFeeConfig(data) {
    return post('/api/sites/setFee',data);
}


//请求主机的目录
export function getAllSitesCategories(data) {
    return get('/api/categories/getAllSitesCategories',data);
}

//查看支付
export function getPaymentInfo(data) {
    return post('/api/sites/getPaymentInfo',data);
}

export function getBlackList(data) {
    return post('/api/sites/getBlackList',data);
}

export function setDmcaConfig(data) {
    return post('/api/sites/setBlacklistConfig',data);
}

export function setADConfig(data) {
    return post('/api/sites/setADConfig',data);
}

export function setBrandConfig(data) {
    return post('/api/sites/setBrandConfig',data);
}