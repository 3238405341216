
import {get,post} from "@/axios";

//分组页面
export function getTemplatesList(data) {
    return get('/api/templates/list',data);
}
export function addTemplates(data) {
    console.log(data,'接收到的数据')
    return post('/api/templates/add',data);
    
}
export function editTemplates(data) {
    console.log(data,'接收到的数据')
    return post('/api/templates/edit',data);
}

export function getAllTemplatesList(data) {
    return get('/api/templates/allTemplatesList',data);
}