
import {get,post} from "@/axios";

//分组页面
export function getLanguagesList(data) {
    return get('/api/languages/list',data);
}
export function addLanguages(data) {
    console.log(data,'接收到的数据')
    return post('/api/languages/add',data);
    
}
export function editLanguages(data) {
    console.log(data,'接收到的数据')
    return post('/api/languages/edit',data);
}

export function getAllLanguagesList(data) {
    return get('/api/languages/allLanguageList',data);
}

