
import {get,post} from "@/axios";

//分组页面
export function getEs_hostList(data) {
    return get('/api/Es_host/list',data);
}
export function addEs_host(data) {
    console.log(data,'接收到的数据')
    return post('/api/Es_host/add',data);
    
}
export function editEs_host(data) {
    console.log(data,'接收到的数据')
    return post('/api/Es_host/edit',data);
}

export function getAllESHostsList(data) {
    return get('/api/es_host/allESHostsList',data);
}

export function getEs_hostGetESCategory(data) {
    return get('/api/es_host/getESCategory',data);
}