
import {get,post} from "@/axios";

//分组页面
export function getServersList(data) {
    return get('/api/servers/list',data);
}
export function addServers(data) {
    console.log(data,'接收到的数据')
    return post('/api/servers/add',data);
    
}
export function editServers(data) {
    console.log(data,'接收到的数据')
    return post('/api/servers/edit',data);
}

export function updateTools(data) {
    console.log(data,'接收到的数据')
    return post('/api/servers/updateTools',data);
}

export function getAllServersList(data) {
    return get('/api/servers/allServersList',data);
}